import 'react-cmdk/dist/cmdk.css'

import { useState } from 'react'
import CommandPalette, {
  filterItems,
  getItemIndex,
  useHandleOpenCommandPalette,
} from 'react-cmdk'
import { Entry } from 'contentful'

import { IPageFields } from 'bl-common/src/generated/contentful'
import { postHogCommandPaletteUrl } from 'bl-posthog/src/constants'
import { getCookie, setCookie } from 'bl-utils/src/cookies'

const baseContentfulLink =
  'https://app.contentful.com/spaces/w65k7w0nsb8q/entries/'

const constructSectionsArray = (fields: IPageFields) => {
  if (!fields.content?.fields || !('sections' in fields.content.fields)) {
    return []
  }
  if ('sections' in fields.content.fields) {
    return fields.content.fields.sections.map(section => ({
      id: `section-${section.sys.id}`,
      children: `Section: ${
        section.fields.heading || section.fields.title || section.fields.name
      }`,
      closeOnSelect: true,
      href: `${baseContentfulLink}${section.sys.id}`,
      target: '_blank',
    }))
  }
}

const toggleGaDebugCookie = () => {
  const current = getCookie('gaDebug')
  const newValue = current === 'on' ? 'off' : 'on'
  setCookie('gaDebug', newValue)
}

const mainActions = [
  {
    id: 'actions',
    heading: 'Common actions',
    items: [
      {
        id: 'pageContentLink',
        children: 'Open Posthog toolbar',
        closeOnSelect: true,
        href: postHogCommandPaletteUrl,
      },
      {
        id: 'gaDebug',
        children: `${
          getCookie('gaDebug') === 'on' ? 'Disable' : 'Enable'
        } Google Analytics Debug`,
        closeOnSelect: true,
        onClick: () => {
          if (typeof window !== 'undefined') {
            toggleGaDebugCookie()
            window.location.reload()
          }
        },
      },
    ],
  },
]

const CommandPaletteWrapper = ({ page }: { page?: Entry<IPageFields> }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [search, setSearch] = useState('')
  useHandleOpenCommandPalette(setIsOpen)

  const filteredItems = filterItems(
    page?.sys
      ? [
          ...mainActions,
          ...(page.sys.id && [
            {
              id: 'main',
              heading: 'Contentful',
              items: [
                {
                  id: 'pageContentLink',
                  children: `Page: ${page.fields.name}`,
                  closeOnSelect: true,
                  href: `${baseContentfulLink}${page.sys.id}`,
                  target: '_blank',
                },
                ...constructSectionsArray(page.fields),
              ],
            },
          ]),
        ]
      : mainActions,
    search
  )
  return (
    <CommandPalette
      onChangeSearch={setSearch}
      onChangeOpen={setIsOpen}
      search={search}
      isOpen={isOpen}
    >
      {filteredItems.length ? (
        filteredItems.map(list => (
          <CommandPalette.List key={list.id} heading={list.heading}>
            {list.items.map(({ id, ...rest }) => (
              <CommandPalette.ListItem
                key={id}
                index={getItemIndex(filteredItems, id)}
                {...rest}
              />
            ))}
          </CommandPalette.List>
        ))
      ) : (
        <CommandPalette.FreeSearchAction />
      )}
    </CommandPalette>
  )
}

export default CommandPaletteWrapper
